import { createSlice } from "@reduxjs/toolkit";

export type List = {
    groups: Group[]
    learningLanguage: string;
    nativeLanguage: string;
}

export type Group = {
    groupId: number;
    name: string;
    learningLanguage: string;
    nativeLanguage: string;
    vocabularyId: number;
    used: boolean;
}

export type ListType = {
    lists: List[]
    currentWords: [],
    vocabularyGroup: Group;
    learningLanguage: string;
    nativeLanguage: string;
}


export const LIST_INITIAL_STATE: ListType = {
    lists: [],
    currentWords: [],
    vocabularyGroup: {
        groupId: 0,
        name: "",
        used: false,
        learningLanguage: "",
        nativeLanguage: "",
        vocabularyId: 0
    },
    learningLanguage: "",
    nativeLanguage: "",
}

export const listSlice = createSlice({
    name: "list",
    initialState: LIST_INITIAL_STATE,
    reducers: {
        setLists(state, action) {
            state.lists = action.payload;
        },
        deleteGroupRedux(state, action) {
            state.lists = state.lists.filter(list => ({ ...list, groups: list.groups.filter(group => group.groupId !== action.payload) }));
        },
        setVocabularyGroup(state, action) {
            state.vocabularyGroup = action.payload;
        },
        setLearningLanguageRedux(state, action) {
            state.learningLanguage = action.payload;
        },
        setNativeLanguageRedux(state, action) {
            state.nativeLanguage = action.payload;
        },
        setCurrentWords(state, action) {
            state.currentWords = action.payload;
        }
    }
})

export const { setLists, deleteGroupRedux, setCurrentWords, setLearningLanguageRedux, setNativeLanguageRedux, setVocabularyGroup } = listSlice.actions;
export const listRecuder = listSlice.reducer;