import {
    AutoComplete,
    Button,
    Form,
    Input,
    Menu,
    message,
    Space,
    Spin,
    Table,
    TableColumnsType,
} from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { BASE_URL } from "../constant";
import {
    selectLists,
    selectVocabularyGroup,
} from "../store/lists/listSelector";
import {
    deleteGroupRedux,
    Group,
    List,
    setLearningLanguageRedux,
    setLists,
    setNativeLanguageRedux,
    setVocabularyGroup,
} from "../store/lists/listSlice";
import { selectToken } from "../store/user/userSelector";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import Column from "antd/es/table/Column";
import { ColumnGroupType, ColumnType } from "antd/es/table/interface";

export const Languages = ["GERMAN", "ENGLISH", "SPANISH"];
type TableDataType = {
    name: JSX.Element | React.ReactElement;
    groupId: number;
    key: number;
};

const SuggestedLists = () => {
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const [searchParams] = useSearchParams();
    const vocabularyGroup = useSelector(selectVocabularyGroup);
    const learningLanguageParam =
        searchParams.get("learningLanguage") ??
        vocabularyGroup.learningLanguage ??
        "";
    const nativeLanguageParam =
        searchParams.get("nativeLanguage") ??
        vocabularyGroup.nativeLanguage ??
        "";
    const lists: List[] = useSelector(selectLists);
    const [learningLanguage, setLearningLanguage] = useState<string>(
        learningLanguageParam,
    );
    const [nativeLanguage, setNativeLanguage] =
        useState<string>(nativeLanguageParam);
    const [opened, setOpened] = useState<boolean>(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<
        TableColumnsType<TableDataType>
    >([]);
    const [initialDataSource, setInitialDataSource] = useState<
        TableColumnsType<TableDataType>
    >([]);

    const onSearch = (e: any) => {
        e.preventDefault();
        const val = e.target.value;
        if (!val || val.length === 0) {
            setDataSource(
                lists[0]?.groups?.map((e) => {
                    return {
                        key: e.groupId,
                        name: (
                            <Link
                                to={`/words/${e.groupId}?learningLanguage=${learningLanguage}&nativeLanguage=${nativeLanguage}`}
                                onClick={() => {
                                    dispatch(
                                        setLearningLanguageRedux(
                                            learningLanguage,
                                        ),
                                    );
                                    dispatch(
                                        setNativeLanguageRedux(nativeLanguage),
                                    );
                                    dispatch(
                                        setVocabularyGroup({
                                            ...e,
                                            learningLanguage,
                                            nativeLanguage,
                                        }),
                                    );
                                }}
                                className="flex flex-col justify-center items-center gap-2 card-body"
                            >
                                {e.name}
                            </Link>
                        ),
                        groupId: e.groupId,
                    };
                }),
            );
            return;
        }

        // @ts-ignore
        setDataSource(initialDataSource.filter((e) =>getStringFromJSX(e.name).toLowerCase().trim().includes(val) || e.groupId.toString().includes(val)));
    };

    const error = (errorMessage: string) => {
        messageApi.error({
            type: "error",
            content: errorMessage,
            style: {
                width: "8rem",
                height: "7rem",
            },
        });
    };
    const fetch = async () => {
        setLoading(true);
        const url = `${BASE_URL}/vocabularyGroup/getVocabularyGroups?learningLanguage=${learningLanguage}&nativeLanguage=${nativeLanguage}`;

        if (
            !token ||
            learningLanguage === "" ||
            nativeLanguage === "" ||
            nativeLanguage === learningLanguage
        ) {
            setLoading(false);
            error("Incorrect language selection");
            return;
        }
        const res = await axios.get(url, {
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });

        console.log(res.data);
        dispatch(
            setLists([
                { groups: [...res.data], learningLanguage, nativeLanguage },
            ]),
        );
        setLoading(false);
    };

    const onClick = (key: string) => {
        if (key.includes("NATIVE")) {
            setNativeLanguage(key.replaceAll("NATIVE", ""));
        } else {
            setLearningLanguage(key);
        }
    };

    const deleteVocabularyGroup = async (groupId: number) => {
        const url = `${BASE_URL}/vocabularyGroup/deletePredefined/${groupId}`;
        const res = await axios.delete(url, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        });

        if (Math.floor(res.status / 100) === 2) {
            message.info("successful delete");
            dispatch(deleteGroupRedux(groupId));
        }
    };

    useEffect(() => {
        setInitialDataSource(
            lists[0]?.groups?.map((e) => {
                return {
                    key: e.groupId,
                    name: (
                        <Link
                            to={`/words/${e.groupId}?learningLanguage=${learningLanguage}&nativeLanguage=${nativeLanguage}`}
                            onClick={() => {
                                dispatch(
                                    setLearningLanguageRedux(learningLanguage),
                                );
                                dispatch(
                                    setNativeLanguageRedux(nativeLanguage),
                                );
                                dispatch(
                                    setVocabularyGroup({
                                        ...e,
                                        learningLanguage,
                                        nativeLanguage,
                                    }),
                                );
                            }}
                            className="flex flex-col justify-center items-center gap-2 card-body"
                        >
                            {e.name}
                        </Link>
                    ),
                    groupId: e.groupId,
                };
            }),
        );
        setDataSource(
            lists[0]?.groups?.map((e) => {
                return {
                    key: e.groupId,
                    name: (
                        <Link
                            to={`/words/${e.groupId}?learningLanguage=${learningLanguage}&nativeLanguage=${nativeLanguage}`}
                            onClick={() => {
                                dispatch(
                                    setLearningLanguageRedux(learningLanguage),
                                );
                                dispatch(
                                    setNativeLanguageRedux(nativeLanguage),
                                );
                                dispatch(
                                    setVocabularyGroup({
                                        ...e,
                                        learningLanguage,
                                        nativeLanguage,
                                    }),
                                );
                            }}
                            className="flex flex-col justify-center items-center gap-2 card-body"
                        >
                            {e.name}
                        </Link>
                    ),
                    groupId: e.groupId,
                };
            }),
        );
        
    }, [lists]);

    async function addNewVocabularyGroup(e: FormDataEvent) {
        // @ts-ignore
        const name = e.vocabularyGroupName;
        // @ts-ignore
        const file = e.file;
        const data = {
            filename: file,
            vocabularyGroupName: name,
            sourceLanguage: learningLanguage,
            targetLanguage: nativeLanguage,
        };
        const url = `${BASE_URL}/scripts/wordsAdding`;
        const res = await axios.post(url, data, {
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
        });
        console.log(res.data);
    }

    const items = Languages.map((lang) => ({ value: lang, key: lang }));
    // @ts-ignore
    const secondItems = items
        .filter((e) => e?.value !== learningLanguage)
        .map((e) => ({ value: e.value, key: e.key + "NATIVE" }));

    useEffect(() => {
        if (learningLanguage && nativeLanguage) {
            fetch();
        }
    }, [learningLanguage, nativeLanguage]);

    const getStringFromJSX = (
        elem: React.ReactElement | JSX.Element | string,
    ): string => {
        if (!elem) {
            return "";
        } else if (typeof elem === "string") {
            return elem;
        } else {
            const children = elem.props.children;
            if (children instanceof Array) {
                return children.map((e) => getStringFromJSX(e)).join("");
            }
            return getStringFromJSX(children);
        }
    };

    return (
        <>
            <div className="flex justify-center items-center flex-col gap-10">
                <div className="flex flex-col gap-5">
                    <div className="flex justify-center items-start gap-2 mt-10 max-md:flex-col">
                        <AutoComplete
                            style={{ width: 200 }}
                            defaultValue={learningLanguage}
                            options={items}
                            onChange={(val, option) => onClick(val)}
                        />
                        <AutoComplete
                            defaultValue={nativeLanguage}
                            style={{ width: 200 }}
                            options={secondItems}
                            onChange={(val, option) => onClick(val + "NATIVE")}
                        />
                    </div>
                    {dataSource && (
                        <Input
                            placeholder="Search"
                            type="search"
                            onChange={onSearch}
                        />
                    )}
                </div>
                <button className="btn btn-sm" onClick={fetch}>
                    Get Predefined
                </button>
                <Space direction="vertical">
                    {learningLanguage && nativeLanguage && lists[0]?.groups && (
                        <Button onClick={() => setOpened(!opened)}>
                            Add Row
                        </Button>
                    )}
                    <Table
                        dataSource={dataSource}
                        pagination={{ defaultPageSize: 25 }}
                        className="flex flex-end w-[31.25rem] max-md:w-[20rem]"
                        // style={{ width: "31.25rem" }}
                        size="large"
                    >
                        <div className="w-[31.25rem] max-md:w-[20rem]">
                            <Column
                                title="Group Id"
                                dataIndex="groupId"
                                key="groupId"
                            />
                            <Column
                                title="Name"
                                dataIndex="name"
                                key="name"
                                sorter={(a: TableDataType, b: TableDataType) =>
                                    getStringFromJSX(a.name).localeCompare(
                                        getStringFromJSX(b.name),
                                    )
                                }
                            />
                            <Column
                                title="Action"
                                key="action"
                                sorter={(a: Group, b: Group) =>
                                    a.groupId - b.groupId
                                }
                                render={(_: any, record: Group) => (
                                    <Space size="middle">
                                        <button
                                            className="underline hover:text-blue-500"
                                            onClick={() => {
                                                deleteVocabularyGroup(
                                                    record.groupId,
                                                );
                                            }}
                                        >
                                            Delete
                                        </button>
                                    </Space>
                                )}
                            />
                        </div>
                    </Table>
                </Space>
            </div>
            {loading && (
                <>
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-black bg-opacity-50 fixed inset-0 z-40 "></div>
                        <Spin
                            size="default"
                            className="z-50"
                            indicator={<LoadingOutlined spin />}
                        />
                    </div>
                </>
            )}
            {opened && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="bg-black bg-opacity-50 fixed inset-0 z-40 "></div>
                    <div className="relative z-50 max-w-md mx-4 sm:mx-8 p-4 sm:p-8 bg-white dark:bg-[#2b333b] shadow-md rounded-2xl">
                        <button
                            className={`cursor-pointer bg-opacity-0 hover:bg-opacity-50 transition-all duration-300 ease-out p-1 rounded-full`}
                            onClick={() => setOpened(false)}
                        >
                            <CloseOutlined />
                        </button>
                        <Form onFinish={addNewVocabularyGroup}>
                            <Form.Item label="Name" name="vocabularyGroupName">
                                <Input
                                    type="text"
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                />
                            </Form.Item>
                            <Form.Item label="File Path" name="file">
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <Button htmlType="submit" type="primary">
                                    Add
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            )}
        </>
    );
};

export default SuggestedLists;
