import { Action, configureStore, Dispatch, Middleware } from '@reduxjs/toolkit';
import { rootReducer } from "./root-reducer";
import { thunk } from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import persistStore from 'redux-persist/es/persistStore';

export type RootState = ReturnType<typeof rootReducer>
const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['list']
};;
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares: Middleware<{}, any, Dispatch<Action>>[] = [
    thunk
];

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(middlewares),
});

export const persistor = persistStore(store);