import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { persistor, store } from './app/store';
import './index.css';
import Login from './pages/Login';
import SuggestedLists from './pages/SuggestedLists';
import Words from './pages/Words';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';
import WordDetails from './pages/WordDetails';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/login" />
  },
  {
    path: "/wordDetails/:userVocabularyId",  
    element: <WordDetails />
  },
  {
    path: "login",
    element: <Login />
  },
  {
    path: "lists",
    element: <SuggestedLists />
  },
  {
    path: "words/:groupId",
    element: <Words />
  }
])

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
    {/* <App /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
