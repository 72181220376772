import { check400 } from "./globalUtils";
import { BASE_URL } from "../constant";

export type AuthenticationResponse = {
    token?: string,
    roomCode?: string,
    lastPickedVocabularyId?: number;
    profileImageUrl?: string;
    interfaceLanguage?: string;
}

export const authenticate = async (loginObj: { email: string, password: string }): Promise<AuthenticationResponse | undefined> => {
    const response = await fetch(`${BASE_URL}/auth/authenticate-admin`, {
        method: "POST",
        body: JSON.stringify(loginObj),
        headers: {
            'Content-Type': 'application/json'
        }
    })


    if (check400(response.status)) return undefined;

    return await response.json().then(data => data);
}

export const register = async (registerObject: { fullName: string, email: string, password: string }): Promise<{ token?: string, roomCode?: string } | undefined> => {
    const response = await fetch(`${BASE_URL}/auth/register`, {
        method: "POST",
        body: JSON.stringify(registerObject),
        headers: {
            'Content-Type': 'application/json'
        }
    })

    if (check400(response.status) || Math.floor(response.status / 100) === 5) return undefined;

    return await response.json().then(data => data);
}