import { ChangeEvent, FormEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setToken, setUserDetails } from "../store/user/userSlice"
import { authenticate } from '../utils/userUtils'
import { useNavigate } from 'react-router-dom'
import { Input } from 'antd'

export type LoginFormParams = {
    updateType: string;
    value: any;
}
function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const INITIAL_LOGIN_OBJ = {
        password: "",
        email: ""
    }
    const [loginObj, setLoginObj] = useState(INITIAL_LOGIN_OBJ)

    const submitForm = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const response = await authenticate(loginObj);
        if (response) {

            dispatch(setToken(response.token))
            dispatch(setUserDetails(response));
            navigate('/lists')
        }
    }

    const updateFormValue = ({ updateType, value }: LoginFormParams) => {
        setLoginObj({ ...loginObj, [updateType]: value })
    }

    return (
        <div className='py-2 px-4 lg:py-24 lg:px-10 bg-white rounded-lg w-full flex justify-center items-center flex-col'>
            <h2 className='text-2xl font-semibold mb-2 text-center'>{"Login"}</h2>
            <form onSubmit={submitForm}>
                <div className="mb-4 flex flex-col gap-3">
                    <Input className="p-2 rounded-lg" autoComplete="username" value={loginObj.email} type="email"
                        onChange={(e) => updateFormValue({
                            updateType: 'email',
                            value: e.target.value
                        })} />
                    <br />
                    <Input className="p-2 rounded-lg" type='password' onChange={(e: ChangeEvent<HTMLInputElement>) => updateFormValue({
                        updateType: 'password',
                        value: e.target.value
                    })} />
                </div>

                <button type="submit"
                    className={"btn btn-ghost text-white bg-button-color hover:bg-blue-500 mt-2 w-full"}>{"Login"}
                </button>
            </form>
        </div>
    )
}

export default Login