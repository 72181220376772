import {
    CloseOutlined,
    LoadingOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {
    AutoComplete,
    Button,
    Form,
    Input,
    message,
    Select,
    Space,
    Spin,
    Upload,
} from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { BASE_URL } from "../constant";
import { selectToken } from "../store/user/userSelector";
import TextArea from "antd/es/input/TextArea";
import { mapPos } from "../utils/globalUtils";

export type PartOfSpeech = "NOUN" | "VERB" | "ADJECTIVE" | "OTHER";
export const PartOfSpeechValues = ["NOUN", "VERB", "ADJECTIVE", "OTHER"];

export const GenderValues = ["MASCULINE", "FEMININE", "NEUTER", "PLURAL"];

export type WordType =
    | "WORD"
    | "SENTENCE"
    | "SPREAD_VERB"
    | "PHRASE"
    | "GERMAN_PHRASAL_VERB"
    | "PHRASAL_VERB";

export interface WordTranslation {
    id?: number | string;
    wordFrom: IWord;
    wordTo: IWord;
}
export interface IWord {
    id: number | string;
    word: string;
    language: string;
    partOfSpeech: PartOfSpeech;
    imageUrl: string;
    soundUrl: string;
    wordDictionary: WordDictionary;
    wordType: WordType;
}
export interface IConjugation {
    id: number | string;
    infinitive: string;
    conjugation: IMappings;
}
export interface IMappings {
    gender?: string;
    mappings: {};
}

export interface WordDictionary {
    description: string;
    descriptionTranslation: string;
    synonyms: string[];
    conjugation: IConjugation;
}

export interface UserVocabulary {
    userVocabularyId?: number;
    wordTranslation?: WordTranslation;
    wordExampleTranslation?: WordExampleTranslation;
    vocabularyGroupId?: number;
}

export interface WordExampleTranslation {
    id?: number | string;
    example?: string;
    exampleTranslation?: string;
    soundUrl?: string;
    wordId?: number | string;
}

const WordDetails = () => {
    const { userVocabularyId } = useParams();
    const token = useSelector(selectToken);
    const [wordDetails, setWordDetails] = useState<UserVocabulary>({});
    const [possibleTranslations, setPossibleTranslations] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            const res = await axios.get(
                `${BASE_URL}/vocabulary/getWord/${userVocabularyId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );

            setWordDetails(res.data);
            setLoading(false);
        };

        fetch()
            .then((res) => console.log(res))
            .catch((err) => console.log(err));
    }, [token, userVocabularyId]);

    console.log("WordDetails", wordDetails);

    async function regenerate() {
        const res = await axios.post(
            `${BASE_URL}/vocabulary/regenerateExamples`,
            {
                userVocabularyId,
                vocabularyGroupId: wordDetails.vocabularyGroupId,
                difficulty: "EASY",
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        console.log(res.data);
    }

    async function getPossibleTranslations() {
        const res = await axios.post(
            `${BASE_URL}/vocabulary/getPossibleTranslations`,
            {
                word: wordDetails.wordTranslation?.wordFrom.word,
                targetLanguage: wordDetails.wordTranslation?.wordTo.language,
                sourceLanguage: wordDetails.wordTranslation?.wordFrom.language,
                wordLimit: 100,
                isAutoComplete: false,
            },
            {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
            },
        );
        setPossibleTranslations(res.data.possibleTranslations);
    }

    async function changeTranslation(e: any) {
        setPossibleTranslations([]);
        await axios.post(
            `${BASE_URL}/vocabulary/changeTranslation`,
            {
                id: userVocabularyId,
                translation: e.translation,
                vocabularyGroupId: wordDetails.vocabularyGroupId,
                partOfSpeech: mapPos(e.pos.toUpperCase()),
            },
            {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
            },
        );
    }

    return (
        <div className="overflow-hidden">
            <div className="flex flex-col gap-10">
                <Link
                    className="underline text-blue-500"
                    to={`/words/${wordDetails.vocabularyGroupId}?learningLanguage=${wordDetails.wordTranslation?.wordFrom.language}&nativeLanguage=${wordDetails.wordTranslation?.wordTo.language}`}
                >
                    To Vocabulary Groups
                </Link>

                <div className="flex max-sm:flex-col gap-2">
                    <Button onClick={regenerate} className="w-40 ml-10">
                        Regenerate Examples
                    </Button>
                    <Button
                        onClick={getPossibleTranslations}
                        className="w-40 ml-10"
                    >
                        Change Translation
                    </Button>

                    {possibleTranslations.map((e) => {
                        return (
                            <Button
                                key={e.translation}
                                onClick={() => changeTranslation(e)}
                            >
                                {e.translation}
                            </Button>
                        );
                    })}
                </div>

                <div className="flex justify-center">
                    <WordForm
                        wordDetails={wordDetails}
                        loading={loading}
                        setLoading={setLoading}
                    />
                </div>
            </div>
            {loading && (
                <>
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-black bg-opacity-50 fixed inset-0 z-40 "></div>
                        <Spin
                            size="default"
                            className="z-50"
                            indicator={<LoadingOutlined spin />}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default WordDetails;

export const Field = ({ k, value }: { k: string; value: any }) => {
    return (
        <p>
            <strong>{k}:</strong>
            {value || k}
        </p>
    );
};

export const WordForm = ({
    wordDetails,
    loading,
    setLoading,
}: {
    wordDetails: UserVocabulary;
    loading: boolean;
    setLoading: (val: boolean) => void;
}) => {
    const [form] = Form.useForm();
    const [word, setWord] = useState<Map<string, any>>(new Map([]));
    const [tempWord, setTempWord] = useState<Map<string, any>>(new Map([]));
    const token = useSelector(selectToken);
    const isMobile = window.innerWidth < 768;

    useEffect(() => {
        setWord(
            new Map<string, any>(
                Object.entries({
                    word: wordDetails.wordTranslation?.wordFrom.word,
                    wordTranslation: wordDetails.wordTranslation?.wordTo.word,
                    partOfSpeech_:
                        wordDetails?.wordTranslation?.wordFrom.partOfSpeech,
                    gender_:
                        wordDetails.wordTranslation?.wordFrom?.wordDictionary
                            ?.conjugation?.conjugation?.gender || "",
                    example: wordDetails.wordExampleTranslation?.example,
                    exampleTranslation:
                        wordDetails.wordExampleTranslation?.exampleTranslation,
                    wordSound: wordDetails.wordTranslation?.wordFrom.soundUrl,
                    imageUrl: wordDetails.wordTranslation?.wordFrom.imageUrl,
                    exampleSound: wordDetails.wordExampleTranslation?.soundUrl,
                    description:
                        wordDetails.wordTranslation?.wordFrom.wordDictionary
                            .description,
                    descriptionTranslation:
                        wordDetails.wordTranslation?.wordFrom.wordDictionary
                            .descriptionTranslation,
                    synonyms:
                        wordDetails.wordTranslation?.wordFrom.wordDictionary.synonyms?.join(
                            ", ",
                        ),
                }),
            ),
        );
    }, [
        wordDetails.wordExampleTranslation?.example,
        wordDetails.wordExampleTranslation?.exampleTranslation,
        wordDetails.wordExampleTranslation?.soundUrl,
        wordDetails.wordTranslation?.wordFrom.imageUrl,
        wordDetails.wordTranslation?.wordFrom.soundUrl,
        wordDetails.wordTranslation?.wordFrom.word,
        wordDetails.wordTranslation?.wordFrom.wordDictionary.description,
        wordDetails.wordTranslation?.wordFrom.wordDictionary
            .descriptionTranslation,
        wordDetails.wordTranslation?.wordFrom.wordDictionary?.synonyms,
        wordDetails.wordTranslation?.wordTo.word,
    ]);

    console.log(word?.entries());

    async function update() {
        setLoading(true);
        const e = tempWord;
        if (e.get("synonyms")) {
            const synonyms = (e.get("synonyms") ?? "").split(",");
            e.set("synonyms", synonyms);
        }

        e.set("userVocabularyId", wordDetails.userVocabularyId);
        if (!e.get("partOfSpeech")) {
            e.set(
                "partOfSpeech",
                wordDetails.wordTranslation?.wordFrom.partOfSpeech,
            );
        }

        const object = Object.fromEntries(e.entries());

        const res = await axios.put(
            `${BASE_URL}/vocabulary/updateWord`,
            object,
            {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
            },
        );
        setLoading(false);
        message.success("Word updated");
        console.log(res.data);
    }

    async function regenerateSound(key: string) {
        setLoading(true);
        const example = key !== "wordSound";

        axios.get(
            `${BASE_URL}/vocabulary/regenerateSounds/${wordDetails.userVocabularyId}?example=${example}`,
            {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
            },
        );
        setLoading(false);
    }

    async function changeWordImage(e: any) {
        setLoading(true);
        const form = new FormData();
        form.append("file", e.target.files[0]);

        await axios.post(
            `${BASE_URL}/vocabulary/changeWordImage/${wordDetails.userVocabularyId}`,
            form,
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
        );

        setLoading(false);
        message.success("Image changed");
    }

    async function reloadWordImage() {
        setLoading(true);
        await axios.get(
            `${BASE_URL}/vocabulary/reloadWordImage/${wordDetails.userVocabularyId}`,
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
        );
        setLoading(false);
    }

    return (
        <Form
            className="flex items-center justify-center gap-3 flex-col w-full"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            form={form}
            name="dynamic_form_complex"
            autoComplete="on"
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    rowGap: 16,
                    flexDirection: "column",
                }}
            >
                {// @ts-ignore
                word
                    // @ts-ignore
                    ?.entries()
                    // @ts-ignore
                    ?.toArray()
                    // @ts-ignore
                    ?.map((e, _, []) => {
                        const [key, value] = e;
                        if (key.toLowerCase().endsWith("_")) {
                            console.log(key + " " + value);

                            return (
                                <Form.Item
                                    key={key}
                                    label={key}
                                    className="w-[87%] ml-3"
                                >
                                    <AutoComplete
                                        className="w-[90%]"
                                        defaultValue={value}
                                        options={(() => {
                                            switch (key) {
                                                case "partOfSpeech_":
                                                    return PartOfSpeechValues.map(
                                                        (a) => ({
                                                            key: a,
                                                            value: a,
                                                        }),
                                                    );
                                                case "gender_":
                                                    return GenderValues.map(
                                                        (i) => ({
                                                            key: i,
                                                            value: i,
                                                        }),
                                                    );
                                                default:
                                                    return [];
                                            }
                                        })()}
                                        onChange={(v) => {
                                            setTempWord(() => {
                                                tempWord.set(
                                                    key.substring(
                                                        0,
                                                        key.length - 1,
                                                    ),
                                                    v,
                                                );

                                                return new Map<string, any>(
                                                    tempWord.entries(),
                                                );
                                            });
                                        }}
                                    >
                                        <Input
                                            className="w-[90%]"
                                            placeholder={value}
                                            defaultValue={value}
                                        />
                                    </AutoComplete>
                                </Form.Item>
                            );
                        }
                        if (key.toLowerCase().includes("sound")) {
                            return (
                                <Form.Item
                                    key={key}
                                    label={key}
                                    className="ml-3"
                                >
                                    <audio
                                        src={value}
                                        controls
                                        className="word-audio"
                                    />
                                    <Button
                                        onClick={() => regenerateSound(key)}
                                        className="mt-2"
                                    >
                                        Regenerate sound
                                    </Button>
                                </Form.Item>
                            );
                        } else if (key.toLowerCase().includes("image")) {
                            return (
                                <Form.Item key={key} label={key} className="flex justify-center items-center flex-wrap">
                                    <img
                                        src={value}
                                        alt="img"
                                        width={isMobile ? 250 : 450}
                                        height={isMobile ? 250 : 450}
                                    />
                                    <Input
                                        type="file"
                                        accept="image/*"
                                        onChange={changeWordImage}
                                        className="hidden mt-4 w-[90%]"
                                    />
                                    <Button
                                        className="mt-3"
                                        onClick={reloadWordImage}
                                    >
                                        Reload image
                                    </Button>
                                </Form.Item>
                            );
                        }

                        return (
                            <FormField
                                key={key}
                                k={key}
                                defaultValue={value}
                                value={value}
                                onChange={(k, v) => {
                                    setTempWord(() => {
                                        tempWord.set(k, v);
                                        return new Map<string, any>(
                                            tempWord.entries(),
                                        );
                                    });
                                }}
                            />
                        );
                    })}
            </div>
            <Button
                className="w-20 h-16 border-1 border-blue-700"
                onClick={update}
            >
                Update
            </Button>
        </Form>
    );
};

export const FormField = ({
    k,
    value,
    onChange,
    defaultValue,
}: {
    defaultValue: any;
    k: string;
    value: any;
    onChange: (key: string, value: string) => void;
}) => {
    return (
        <Form.Item
            className="ml-3"
            initialValue={defaultValue}
            label={k}
            name={[value]}
        >
            <TextArea
                className="w-[90%]"
                onChange={(e) => onChange(k, e.target.value)}
            />
        </Form.Item>
    );
};
