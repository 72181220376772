import { RootState } from "../../app/store";


export const selectLists = (state: RootState) => state.list.lists;

export const selectVocabularyGroup = (state: RootState) => state.list.vocabularyGroup;

export const selectLearningLanguage = (state: RootState) => state.list.learningLanguage;

export const selectNativeLanguage = (state: RootState) => state.list.nativeLanguage;

export const selectCurrentWords = (state: RootState) => state.list.currentWords;