import { AutoComplete, Button, Input, Space, Spin, Table, message } from "antd";
import Column from "antd/es/table/Column";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import Popup from "../components/Popup";
import { BASE_URL } from "../constant";
import { selectVocabularyGroup } from "../store/lists/listSelector";
import { selectToken } from "../store/user/userSelector";
import { mapPos } from "../utils/globalUtils";

export type Word = {
    userVocabularyId: number;
    wordFrom: string;
    wordTo: string;
    vocabularyGroupId: number;
    vocabularyId: number;
    wordTranslationId: number;
    wordFromId: number;
    wordToId: number;
    partOfSpeech: string;
};

const Words = () => {
    const { groupId } = useParams();
    const [searchParams] = useSearchParams();
    const token = useSelector(selectToken);
    const vocabularyGroup = useSelector(selectVocabularyGroup);
    const learningLanguage =
        searchParams.get("learningLanguage") ??
        vocabularyGroup.learningLanguage;
    const nativeLanguage =
        searchParams.get("nativeLanguage") ?? vocabularyGroup.nativeLanguage;
    const [initialWords, setInitialWords] = useState<Word[]>([]);
    const [words, setWords] = useState<Word[]>([]);
    const [translations, setTranslations] = useState<any[]>([]);
    const [translationsOpen, setTranslationsOpen] = useState<boolean>(false);
    const [translationOptions, setTranslationOptions] = useState<any[]>([]);
    const [word, setWord] = useState<string>();
    const [id, setId] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);
    const [messageApi, contextHolder] = message.useMessage();
    
    const error = (errorMessage: string) => {
        messageApi.error({
            type: "error",
            content: errorMessage,
            style: {
                width: "8rem",
                height: "7rem"
            }
        })
    }

    useEffect(() => {
        if (!token || !groupId) return;

        const fetch = async () => {
            setLoading(true);
            const { data } = await axios.get(
                `${BASE_URL}/vocabulary/getWords?vocabularyGroupId=${groupId}`,
                {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                },
            );

            setWords(data.words.reverse());
            setInitialWords(data.words.reverse());
            setLoading(false);
        };

        fetch().then();
    }, [groupId, token]);

    function distinctTranslations(arr: any[]) {
        const visited: any[] = [];

        for (let e of arr) {
            const find = visited.find((v) => v.translation === e.translation);
            if (!find) {
                visited.push(e);
            }
        }

        return visited;
    }

    async function getTranslations(value: string, popup: boolean, id?: number) {
        const data = {
            word: value,
            targetLanguage: nativeLanguage,
            sourceLanguage: learningLanguage,
            wordLimit: 100,
        };
        debugger;
        const res = await axios.post(
            `${BASE_URL}/vocabulary/getPossibleTranslations`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            },
        );
        if (!res) return;

        const result = distinctTranslations(res.data.possibleTranslations);
        console.log("🚀 ~ getPossTranslations ~ result:", result);
        if (popup) {
            setTranslations(result);
            setTranslationsOpen(true);
        } else {
            setTranslationOptions(
                result.map((e) => ({
                    value: e.translation + "\t" + e.pos,
                    key: e.translation,
                })),
            );
        }
        setWord(value);
        if (id) {
            setId(id);
        }
    }

    async function deleteWord(id: number) {
        const url = `${BASE_URL}/vocabulary/delete-word-admin?userVocabularyId=${id}`;
        const res = await axios.delete(url, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });

        if (res.status / 100 === 4) {
            return;
        }

        const updatedWords = words.filter(
            (word) => word?.userVocabularyId !== id,
        );
        setWords(updatedWords);
    }

    const dataSource = words.map((word) => ({
        key: word.userVocabularyId,
        id: word.userVocabularyId,
        wordFrom: (
            <Link
                to={`/wordDetails/${word.userVocabularyId}`}
                className="text-blue-500 underline"
            >
                {word.wordFrom}
            </Link>
        ),
        wordFromValue: word.wordFrom,
        wordTo: word.wordTo,
        partOfSpeech: word.partOfSpeech,
    }));

    async function onSelect(value: string) {
        console.log("🚀 ~ value:", value);

        if (!word) {
            error("Word is missing");
            return;
        } else if(!groupId) {
            error("Group id is missing")
            return;
        } else if (!vocabularyGroup.vocabularyId) {
            error("Vocabulary id is missing");
            return;
        }
        
        const translation = value.split("\t")[0];
        const pos = value.split("\t")[1];

        const tempId = uuid();

        const addWordData = {
            tempId,
            word,
            vocabularyId: vocabularyGroup.vocabularyId,
            vocabularyGroupId: +groupId,
            wordTranslation: { translation, pos },
        };

        await axios.post(`${BASE_URL}/vocabulary/add-word`, addWordData, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        });
    }

    async function changeTranslation(translation: any) {
        setLoading(true);
        console.log("🚀 ~ changeTranslation ~ translation:", translation);
        if (!groupId) {
            setLoading(false)
            error("Group id is missing");
            return;
        }
        
        
        await axios.post(
            BASE_URL + "/vocabulary/changeTranslation",
            {
                id,
                translation: translation.translation,
                vocabularyGroupId: +groupId,
                partOfSpeech: mapPos(translation.pos.toUpperCase()),
            },
            {
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
        );
        setLoading(false);
    }

    function filter(value: string) {
        if (!value || value.length === 0) {
            setWords(initialWords);
            return;
        }

        const filtered = words.filter((a) => a.wordFrom.includes(value));

        setWords(filtered);
    }

    async function regenerateImagesForAllWords() {
        setLoading(true);
        const url = `${BASE_URL}/vocabulary/regenerateImagesForAllWords/${groupId}`;
        await axios.get(url, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        setLoading(false);
    }

    return loading ? (
        <Spin size="large" className="flex justify-center items-center" />
    ) : (
        <>
            <Link className="underline text-blue-500" to={`/lists?learningLanguage=${learningLanguage}&nativeLanguage=${nativeLanguage}`}>
                &#8249; To Main Page
            </Link>
            <div className="flex justify-center flex-col items-center w-full">
                <AutoComplete
                    popupMatchSelectWidth={252}
                    style={{ width: 300 }}
                    options={translationOptions}
                    onSelect={onSelect}
                    size="large"
                    className="my-7"
                >
                    <Input.Search
                        size="large"
                        placeholder="Add New Word"
                        enterButton
                        onSearch={(value) => getTranslations(value, false)}
                    />
                </AutoComplete>

                <Input.Search
                    style={{ width: 300 }}
                    size="large"
                    placeholder="Search"
                    enterButton
                    onChange={(e) => filter(e.target.value)}
                />

                <Button
                    type="primary"
                    className="my-7"
                    onClick={regenerateImagesForAllWords}
                >
                    Regenerate Images for all words
                </Button>

                <span>Number of words: {words.length}</span>
                <Table
                    dataSource={dataSource}
                    className="flex justify-center max-md:w-[80%]"
                    pagination={{ defaultPageSize: 20 }}
                >
                    <Column title="Word" dataIndex="wordFrom" key="wordFrom" />
                    <Column
                        title="Translation"
                        dataIndex="wordTo"
                        key="wordTo"
                    />
                    <Column
                        title="Part Of Speech"
                        dataIndex="partOfSpeech"
                        key="partOfSpeech"
                    />
                    <Column
                        title="Action"
                        key="action"
                        render={(_: any, record: any) => (
                            <Space
                                size="small"
                                className="flex max-sm:flex-col max-sm:gap-2"
                            >
                                {/* @ts-ignore */}
                                <button
                                    className="underline hover:text-blue-500"
                                    onClick={() =>
                                        getTranslations(
                                            record.wordFromValue,
                                            true,
                                            record.id,
                                        )
                                    }
                                >
                                    Change Translation
                                </button>
                                <button
                                    className="underline text-red-500 hover:text-blue-500"
                                    onClick={() => {
                                        // @ts-ignore
                                        deleteWord(record.id);
                                    }}
                                >
                                    Delete
                                </button>
                            </Space>
                        )}
                    />
                </Table>
                {translationsOpen && (
                    <Popup onClose={() => setTranslationsOpen(false)}>
                        {translations?.map((e) => (
                            <button
                                key={e.translation}
                                onClick={() => changeTranslation(e)}
                                className="btn w-full flex gap-4"
                            >
                                <span>{e.translation}</span>
                                <span>{e.pos}</span>
                            </button>
                        ))}
                    </Popup>
                )}
            </div>
        </>
    );
};

export default Words;
