export const BASE_URL = get();

function get() {
    if (process.env.NODE_ENV === "production") {
        return "https://api.fluent-fusion.com";
    } else {
        return "http://localhost:9090";
        // return "https://api.fluent-fusion.com";
    }
}
